import React from 'react'
import { graphql } from 'gatsby';

import { Layout } from '../components/shared/Layout';
import { Introduction } from '../components/research/Introduction';
import { Publications } from '../components/research/Publications';
import { SEO } from '../components/seo';
import { dataWithImages, getOptimisedImage } from '../utils/images';

export default function AboutPage({ data, location }) {
  const { about, publications, images } = data;

  return (
    <Layout location={location}>
      <SEO title="Research" />
      <Introduction
        data={dataWithImages(about, getOptimisedImage(about.frontmatter.photo, images))}
      />
      <Publications data={publications} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    images: allImageSharp {
      nodes {
        fluid(maxWidth: 440) {
          ...GatsbyImageSharpFluid
          originalName
        }
      }
    },
    about: markdownRemark(frontmatter: { pid: { eq: "about-about" }}) {
      frontmatter {
        photo
        title
      },
      html
    }
    publications: markdownRemark(frontmatter: { pid: { eq: "about-publications" }}) {
      frontmatter {
        publications {
          details
          title
          url
        }
      }
    }
  }
`;

