import React from 'react';
import Img from 'gatsby-image'
import { Container } from '../../shared/Container';

import styles from './introduction.module.css';

export function Introduction({ data: { frontmatter: { photo, title }, html, optimisedImage } }) {
  return (
    <div className={`bg-greyMedium py-10 md:pt-20 md:pb-0 px-5 ${styles.introduction}`}>
      <Container className="min-h-35r h-full border-4 border-secondary shadow-xl bg-white p-1 flex sm:block lg:flex flex-col lg:flex-row items-center lg:items-start">
        {
          optimisedImage
            ? (
                <Img
                  fluid={optimisedImage.fluid}
                  alt="Dr. Zeina Haoula"
                  className={styles.aboutPhoto}
                />
            )
            : <img src={photo} alt="Dr. Zeina Haoula" className={styles.aboutPhoto} />
        }
        <div>
          <div className="p-5 lg:p-10">
            <h1 className="mb-3 text-4xl md:text-5xl text-center font-serif">{title}</h1>
            <div className="text-lg" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </Container>
    </div>
  )
}
