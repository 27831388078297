import React from 'react';

import { Container } from '../../shared/Container'

import styles from './publications.module.css';

export function Publications({ data: { frontmatter: { publications } }}) {
  return (
    <div className="px-5">
      <Container className="pt-10 sm:pt-20 pb-10">
        <div className="flex items-center justify-center mb-10">
          <span className="hidden md:inline-block sm:h-1 mr-2 mt-1 w-20 bg-secondary" />
          <h2 className="text-3xl">Publications</h2>
          <span className="hidden md:inline-block sm:h-1 ml-2 mt-1 w-full bg-secondary" />
        </div>
        <div>
          {publications.map(({ title, url, details } ) => (
            <div className={`mb-5 ${styles.publication}`} key={title}>
              {url
              ? <a className="underline hover:no-underline font-bold text-greyDark" href={url}>{title}</a>
              : <span className="font-bold text-greyDark">{title}</span> }
              {(title || url) && ' — '} {details}
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}
